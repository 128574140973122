<template>
    <div id="kof-tools">
        <div class="vx-row">

            <div class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                        <span>Usuarios</span>.
                    </h1>
                </vx-card>
            </div>

            <div class="vx-col w-full mb-6">
                <vx-card title="Gestiona usuarios">
                    <p class="mb-4">En este apartado podrás administrar los usuarios dentro de tu programa.</p>
                    <vs-button class="w-1/6" color="dark" @click="openCreateMModel">Crear usuario</vs-button>
                    <vs-button v-if="isProgramAdmin" class="w-1/4 ml-4" type="line" color="danger"
                        @click="isVieUserAdminModalOpened = true">Ver usuarios administradores</vs-button>
                    <vs-button v-if="isProgramAdmin" class="w-1/10 float-right" color="dark"
                        @click="showDialog = true">Cargar UOs</vs-button>
                </vx-card>
            </div>

            <div class="vx-col w-full mb-6">
                <vx-card title="Tabla de visualización">
                    <vs-table ref="usersTable" stripe search noDataText="No se han generado registros" :data="usersList"
                        pagination :max-items="usersPerPage">
                        <div slot="header"
                            class="flex flex-wrap-reverse items-center flex-grow justify-between mt-4 ml-4 mb-4">
                            <vs-dropdown v-if="isMounted" vs-trigger-click
                                class="cursor-pointer mr-4 items-per-page-handler">
                                <div
                                    class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                    <span class="mr-2">
                                        {{ usersPage * usersPerPage - (usersPerPage - 1) }} -
                                        {{ usersList.length - usersPage * usersPerPage > 0 ? usersPage * usersPerPage :
                                            usersList.length }} de {{ queriedUsersItems }}
                                    </span>
                                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                                </div>
                                <vs-dropdown-menu>
                                    <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="usersPerPage = it"
                                        :key="it">
                                        <span>{{ it }}</span>
                                    </vs-dropdown-item>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>

                        <template slot="thead">
                            <!-- <vs-th class="bold" sort-key="user_id">UID</vs-th> -->
                            <vs-th class="bold" sort-key="userType">TIPO</vs-th>
                            <vs-th class="bold" sort-key="subregion_id">ID LÍDER DE VENTA</vs-th>
                            <vs-th class="bold" sort-key="email">EMAIL</vs-th>
                            <vs-th class="bold" sort-key="full_name">NOMBRE</vs-th>
                            <!-- <vs-th class="bold" sort-key="region">ZONA/REGIÓN</vs-th> -->
                            <vs-th class="bold" sort-key="active">
                                <span class="mr-2">ESTADO</span>
                                <vx-tooltip title="¿Para qué es esto?" color="dark"
                                    style="float:right;cursor:pointer;margin-top:-8px"
                                    text="Indica sí el usuario está activo o inactivo. En caso de estar inactivo no podrá iniciar sesión.">
                                    <vs-chip color="dark">?</vs-chip>
                                </vx-tooltip>
                            </vs-th>
                            <vs-th class="bold">ÚLTIMO LOGIN</vs-th>
                            <vs-th class="bold">ACCIONES</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(tableRow, index) in data">
                                <!-- <vs-td :data="data[index].id">
								{{ data[index].id }}
							</vs-td> -->

                                <vs-td :data="data[index].userType">
                                    {{ getUserType(data[index].userType) }}
                                </vs-td>

                                <vs-td :data="data[index].userType">
                                    {{ data[index].subregion_manager_id ? data[index].subregion_manager_id : "N/A" }}
                                </vs-td>

                                <vs-td :data="data[index].email">
                                    {{ data[index].email }}
                                </vs-td>

                                <vs-td :data="getFullName(data[index])">
                                    {{ getFullName(data[index]) }}
                                </vs-td>

                                <!-- <vs-td :data="getArea(data[index])">
								{{ getArea(data[index]) }}
							</vs-td> -->

                                <vs-td :data="getStatus(data[index])">
                                    {{ getStatus(data[index]) }}
                                </vs-td>
                                <vs-td :data="data[index].last_login">
                                    {{ data[index].last_login != null ? dateTimeFormat(data[index].last_login) : "N/A" }}
                                </vs-td>

                                <vs-td :data="data[index].id">
                                    <vx-tooltip title="Editar usuario." color="dark"
                                        style="float:left;cursor:pointer;margin-top:-8px" class="mt-1"
                                        text="Edita la información básica del usuario.">
                                        <vs-button @click.stop="openEditModal(data[index])" type="flat" class="rgred"
                                            icon="edit"></vs-button>
                                    </vx-tooltip>
                                    <vx-tooltip title="Inhabilitar usuario." color="dark"
                                        style="float:right;cursor:pointer;margin-top:-8px" class="mt-1"
                                        text="Cambia el estado del usuario para controlar su acceso a la plataforma.">
                                        <feather-icon icon="ToggleRightIcon"
                                            svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                                            class="mt-3" @click.stop="handleRemoveUser(data[index])" />
                                    </vx-tooltip>
                                    <vx-tooltip v-if="isInnactiveUser(data[index])" title="Enviar email a usuario."
                                        color="dark" style="float:right;cursor:pointer;margin-top:-8px" class="mt-1"
                                        text="Envía el correo de activación de cuenta al líder de venta..">
                                        <feather-icon icon="MailIcon"
                                            svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                                            class="mt-3 rgred" @click.stop="sendEmailNotification(data[index].id)" />
                                    </vx-tooltip>
                                </vs-td>
                            </vs-tr>
                        </template>

                    </vs-table>
                </vx-card>
            </div>
        </div>

        <vs-popup :active.sync="isCreateUserModalOpened" :title="modalTitle">
            <create-user-control ref="createUserControl" :currentUser="currentUser" :showCloseButton="true"
                :regionsList="regionsList" :zonesList="zonesList" @on-save="handleOnSave()"
                @on-close="isCreateUserModalOpened = false" />
        </vs-popup>

        <vs-popup :active.sync="isVieUserAdminModalOpened" title="Administradores.">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-table ref="adminsTable" stripe :data="usersAdminList" noDataText="No se han generado registros">
                        <div class="mb-2" slot="header">
                            <h4>Lista de usuarios adiministradores.</h4>
                        </div>

                        <template slot="thead">
                            <vs-th class="bold" sort-key="name">NOMBRE</vs-th>
                            <vs-th class="bold" sort-key="email">EMAIL</vs-th>
                            <vs-th class="bold" sort-key="last_login">ÚLTIMO LOGIN</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(tableRow, index) in data">
                                <vs-td :data="data[index].name">
                                    {{ data[index].name }}
                                </vs-td>
                                <vs-td :data="data[index].email">
                                    {{ data[index].email }}
                                </vs-td>
                                <vs-td :data="data[index].last_login">
                                    {{ data[index].last_login != null ? dateTimeFormat(data[index].last_login) : "N/A" }}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
        </vs-popup>

        <!-- DOCUMENT FILES POPUP -->
        <vs-popup title="Alta de UO" :active.sync="showDialog">
            <p class="mb-4">Asegúrate que el formato del archivo sea en CSV (*.csv) y se hayan respetado el nombre, orden y
                número de columnas. El nombre del archivo no es relevante.
            </p>
            <input class="m-4" ref="fileInput" id="subregion_layout_file" text="Seleccione el layout" accept=".csv"
                fileName="file" type="file" /> <br>
            <vs-button @click="requestPostLayout()" class="m-4" color="dark">Cargar</vs-button>
        </vs-popup>

    </div>
</template>

<script>
import CreateUserControl from "./modals/CreateUserControl.vue";
import dateHelper from '@mixins/dateHelper';

export default {
    name: 'UsersCatalog',
    mixins: [dateHelper],
    components: {
        CreateUserControl
    },
    data() {
        return {
            isMounted: false,
            usersList: [],
            usersAdminList: [],
            usersPerPage: 10,
            currentUser: {},
            regionsList: [],
            zonesList: [],
            itemsPerPageDefinitions: [10, 25, 50],
            isCreateUserModalOpened: false,
            isVieUserModalOpened: false,
            isVieUserAdminModalOpened: false,
            modalTitle: 'Crear usuario',
            showDialog: false,

            // constants
            ZONE_MANAGER: 1,
            REGION_MANAGER: 2,
            EXECUTIVE_MANAGER: 3,
            SUBREGION_LEADER: 4
        }
    },
    async mounted() {
        this.showLoading(true);
        await this.getUsersList();
        await this.getAdminUsersList();
        await this.getRegionsList();
        await this.getZonesList();
        this.isMounted = true;
        this.showLoading(false);
    },
    watch: {
        isCreateUserModalOpened(newValue) {
            if (!newValue) {
                this.currentUser = {};
                this.$refs.createUserControl.clear();
            }
        }
    },
    methods: {
        isInnactiveUser(row) {
            if (row.userType != this.SUBREGION_LEADER) {
                return false;
            }

            // return row.last_login === null; VALIDACIÓN POR SI NO TIENEN INICIO DE SESIÓN EN PLATAFORMA. 
            return true;
        },
        async sendEmailNotification(user_id) {
            try {
                this.showLoading(true);
                await axios.post(`api/kof/post/resend-email/${user_id}`)
                this.showLoading(false);
                this.saveSuccessNotif(null, "Hemos enviado un recordatorio a este usuario para inicie sesión en la plataforma.")
            } catch (error) {
                this.failedOperationNotif("Ha ocurrido un problema.");
            }
        },
        async getUsersList() {
            try {
                const { data } = await axios.get(`api/kof/get/users?programId=${this.currentProgramData.id}`);
                if (data.success) {
                    this.usersList = data.message;
                    return;
                }
                console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        async getAdminUsersList() {
            try {
                const { data } = await axios.get(`api/kof/get/users/admins?programId=${this.currentProgramData.id}`);
                if (data.success) {
                    this.usersAdminList = data.message;
                    return;
                }
                console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        async getRegionsList() {
            try {
                const { data } = await axios.get('api/kof/get/regions');
                if (data.success) {
                    this.regionsList = data.message;
                    return;
                }
                console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        async getZonesList() {
            try {
                const { data } = await axios.get('api/kof/get/zones');
                if (data.success) {
                    this.zonesList = data.message;
                    return;
                }
                console.log(data.message);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        handleOnSave() {
            this.isCreateUserModalOpened = false;
            this.getUsersList();
            this.getRegionsList();
            this.getZonesList();
        },
        openCreateMModel() {
            this.modalTitle = 'Crear usuario';
            this.isCreateUserModalOpened = true;
        },
        openEditModal(currentItem) {
            this.currentUser = currentItem;
            this.currentUser.firstName = currentItem.first_name;
            this.currentUser.secondName = currentItem.second_name;
            this.currentUser.firstLastName = currentItem.last_name_1;
            this.currentUser.secondLastName = currentItem.last_name_2;
            this.modalTitle = 'Editar usuario';

            this.isCreateUserModalOpened = true;
        },
        handleRemoveUser(currentItem) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: currentItem.active == 1 ? 'Dar de baja usuario' : 'Reactivar usuario',
                text:
                    currentItem.active == 1 ?
                        `¿Estás seguro que deseas INHABILITAR al usuario ${this.getFullName(currentItem)}? Una vez inhabilitado no podrá iniciar sesión.` :
                        `¿Estás seguro que deseas HABILITAR al usuario ${this.getFullName(currentItem)}?`,
                acceptText: "Aceptar",
                cancelText: 'Cancelar',
                accept: () => this.toggleStatus(currentItem)
            });
        },
        async toggleStatus(currentItem) {
            try {
                this.showLoading(true);
                await axios.post(`api/kof/post/toggle-status`, currentItem);
                await this.getUsersList();
                await this.getRegionsList();
                await this.getZonesList();
                this.showLoading(false);
            }
            catch (error) {
                console.log(error);
            }
            this.warn('Ha ocurrido un error');
        },
        getFullName(currentItem) {
            let fullName = currentItem.first_name;

            if (currentItem.second_name)
                fullName += ` ${currentItem.second_name}`;

            fullName += ` ${currentItem.last_name_1}`;

            if (currentItem.last_name_2)
                fullName += ` ${currentItem.last_name_2}`;

            return fullName;
        },
        getArea(currentItem) {
            if (!currentItem.active)
                return 'Ninguna'

            let area = currentItem.zone_name || 'Ninguna';

            if (currentItem.zone_name && currentItem.region_name)
                area += ` | ${currentItem.region_name}`;

            return area;
        },
        getStatus(currentItem) {
            if (currentItem.active == 1)
                return 'Activo';
            return 'Inactivo';
        },
        getUserType(userType) {
            switch (userType) {
                case this.ZONE_MANAGER:
                    return 'Gerente de zona';
                case this.REGION_MANAGER:
                    return 'Gerente de región';
                case this.EXECUTIVE_MANAGER:
                    return 'Ejecutivo de programa';
                case this.SUBREGION_LEADER:
                    return 'Líder de ventas';
            }
        },
        async requestPostLayout() {
            let ok = document.getElementById("subregion_layout_file").files.length > 0;
            if (!ok) {
                this.missingFieldsNotif();
                return;
            }
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: 'Cargar layout de UO.',
                text: `¿Estás seguro que deseas cargar el layout? La BD existente será actualizada.`,
                acceptText: "Cargar layout",
                cancelText: 'Cancelar',
                accept: this.doPostLayout
            });
        },
        async doPostLayout() {
            try {
                let doc = document.getElementById("subregion_layout_file");
                const data = new FormData();
                if (doc.files.length > 0) {
                    this.showLoading(true);
                    data.append("subregion_layout", doc.files[0]);
                    const res = await axios.post(`/api/kof/post/subRegionManager/layout?programId=${this.programIdSelected}`, data);
                    const sessionDate = this.midDateFormat(new Date());

                    // COMPLEMENTA EL STREAM DE ARCHIVOS CON UN MAGÍA DEL DOM
                    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `CARGA_LAYOUT_SESION_${sessionDate}.csv`);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    doc.value = "";
                    this.showDialog = false;
                    await this.getUsersList();
                    await this.getAdminUsersList();
                    await this.getRegionsList();
                    await this.getZonesList();
                    this.showLoading(false);
                    this.saveSuccessNotif();
                } else {
                    this.warningNotif("Importante", "No se ha seleccionado ningún layout para cargar.");
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.showLoading(false);
            }
        },
    },
    computed: {
        usersPage() {
            if (this.isMounted)
                return this.$refs.usersTable.currentx;
            return 0;
        },
        queriedUsersItems() {
            return this.$refs.usersTable ? this.$refs.usersTable.queriedResults.length : this.usersList.length
        },
        isProgramAdmin() {
            return this.$acl.check("isProgramAdmin");
        },
        programIdSelected () {
           return this.currentProgramData.id;
        }
    }
}
</script>

<style>
.con-vs-dialog {
    z-index: 90000;
}
</style>