<template>
    <div>
        <div class="vx-row mb-4">
            <div class="vx-col w-full">
                <label class="vs-input--label">Tipo de usuario</label>
                <v-select
                    class="vs-custom w-full"
                    name="userType"
                    label="text"
                    v-validate="ruleRequired"
                    v-model.lazy="currentUser.userType"
                    :options="userTypesList"
                    :reduce="text => text.value"
                    :clearable="false"
                    :searchable="true"
                    placeholder="Seleccione una opción">
                    <div slot="no-options">No hay coincidencias</div>
                </v-select>
				<v-error v-if="hasError('userType')" :error="getErrorText('userType')" />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <vs-input
                    class="w-full mb-4"
                    name="firstName"
                    label="Primer nombre *"
                    placeholder="Requerido"
                    v-validate="ruleRequired"
                    v-model.lazy="currentUser.firstName"
                    icon-pack="feather"
                    :danger="hasError('firstName')"
                    :danger-text="getErrorText('firstName')"
                    :success="isSuccessful('firstName')"
                    v-on="{ input: e => { currentUser.firstName = upper(e) } }"
                />
            </div>
            <div class="vx-col w-1/2">
                <vs-input
                    class="w-full mb-4"
                    name="secondName"
                    label="Segundo nombre"
                    placeholder="(Opcional)"
                    v-model.lazy="currentUser.secondName"
                    icon-pack="feather"
                    :danger="hasError('secondName')"
                    :danger-text="getErrorText('secondName')"
                    :success="isSuccessful('secondName')"
                    v-on="{ input: e => { currentUser.secondName = upper(e) } }"
                />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-1/2">
                <vs-input
                    class="w-full mb-4"
                    name="firstLastName"
                    label="Primer apellido *"
                    placeholder="Requerido"
                    v-validate="ruleRequired"
                    v-model.lazy="currentUser.firstLastName"
                    icon-pack="feather"
                    :danger="hasError('firstLastName')"
                    :danger-text="getErrorText('firstLastName')"
                    :success="isSuccessful('firstLastName')"
                    v-on="{ input: e => { currentUser.firstLastName = upper(e) } }"
                />
            </div>
            <div class="vx-col w-1/2">
                <vs-input
                    class="w-full mb-4"
                    name="secondLastName"
                    label="Segundo apellido"
                    placeholder="(Opcional)"
                    v-model.lazy="currentUser.secondLastName"
                    icon-pack="feather"
                    :danger="hasError('secondLastName')"
                    :danger-text="getErrorText('secondLastName')"
                    :success="isSuccessful('secondLastName')"
                    v-on="{ input: e => { currentUser.secondLastName = upper(e) } }"
                />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <vs-input
                    class="w-full mb-4"
                    name="email"
                    label="Correo del usuario *"
                    placeholder="Requerido"
                    v-validate="ruleEmail"
                    v-model.lazy="currentUser.email"
                    icon-pack="feather"
                    :danger="hasError('email')"
                    :danger-text="getErrorText('email')"
                    :success="isSuccessful('email')"
                />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                
                <div class="flex">
                    <vue-country-code
                        name="dialCode"
                        class="vs-custom dial-code"
                        data-vv-name="dialCode"
                        :enableSearchField="true"
                        searchPlaceholderText="Buscar código de país"
                        :enabledCountryCode="true"
                        @onSelect="onSelect"
                        :defaultCountry="preferredCountries"
                        :preferredCountries="['MX']">
                    </vue-country-code>
                    <vs-input
                        class="w-full mb-4"
                        name="phone"
                        type="tel"
                        label="Teléfono a 10 dígitos *"
                        placeholder="Requerido"
                        v-validate="rulePhone"
                        maxlength="10"
                        v-model.lazy="currentUser.phone"
                        icon-pack="feather"
                        :danger="hasError('phone')"
                        :danger-text="getErrorText('phone')"
                        :success="isSuccessful('phone')"
                    />
                </div>
            </div>
        </div>
        <!-- <template v-if="currentUser.userType != EXECUTIVE_MANAGER">
            <hr class="my-8" />
            <div class="vx-row mb-4" v-if="currentUser.userType == SUBREGION_LEADER" >
                <div class="vx-col w-full">
                    <label class="vs-input--label">Zona *</label>
                    <v-select
                        class="vs-custom w-full"
                        name="zone"
                        label="text"
                        v-validate="ruleRequired"
                        v-model.lazy="currentUser.zone"
                        :options="getZonesListOptions"
                        :reduce="text => text.value"
                        :clearable="false"
                        :searchable="true"
                        placeholder="Seleccione una opción">
                        <div slot="no-options">No hay coincidencias</div>
                    </v-select>
                    <v-error v-if="hasError('zone')" :error="getErrorText('zone')" />
                </div>
            </div>
            <div v-if="currentUser.userType == SUBREGION_LEADER" class="vx-row mb-4">
                <div class="vx-col w-full">
                    <label class="vs-input--label">Región *</label>
                    <v-select
                        class="vs-custom w-full"
                        name="region"
                        label="text"
                        v-validate="ruleRequired"
                        v-model.lazy="currentUser.region"
                        :options="getRegionsListOptions"
                        :reduce="text => text.value"
                        :clearable="false"
                        :searchable="true"
                        :disabled="isRegionManager"
                        placeholder="Seleccione una opción">
                        <div slot="no-options">No hay coincidencias</div>
                    </v-select>
				    <v-error v-if="hasError('region')" :error="getErrorText('region')" />
                </div>
            </div>
        </template> -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <vs-alert v-if="errorMessage" color="danger">
                    <span>{{ errorMessage }}</span>
                </vs-alert>
            </div>
        </div>
        <div class="vx-row my-5">
            <div class="vx-col w-1/2" :class="{ 'w-full': !showCloseButton }">
                <vs-button class="w-full" @click="handleSave()" color="dark">Guardar</vs-button>
            </div>
            <div class="vx-col w-1/2" v-if="showCloseButton">
                <vs-button class="w-full" @click="handleClose()" type="border" color="dark">Cerrar</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";

export default {
    name: 'CreateUserControl',
    mixins: [
        inputHelper
    ],
    props: {
        currentUser: { required: true, type: Object },
        showCloseButton: { required: false, type: Boolean, default: false },
        regionsList: { required: true, type: Array },
        zonesList: { required: true, type: Array }
    },
    data() {
        return {
            ruleRequired: 'required',
            ruleEmail: 'required|email',
            rulePhone: 'required|digits:10',
            errorMessage: null,
            preferredCountries: 'MX',

            // constants
            ZONE_MANAGER: 1,
            REGION_MANAGER: 2,
            EXECUTIVE_MANAGER: 3,
            SUBREGION_LEADER: 4
        }
    },
    mounted() {
        this.currentUser.country_calling_code = this.preferredCountries;
    },
    methods: {
        onSelect({ dialCode }) {
            this.currentUser.country_calling_code = `+${dialCode}`;
        },
        async handleSave() {
            this.showLoading(true);
            try {
                this.errorMessage = null;

                const validity = await this.$validator.validateAll();
                if(!validity) {
                    this.showLoading(false);
                    return;
                }
                
                const {data} = await axios.post(`api/kof/post/user?programId=${this.currentProgramData.id}`, this.currentUser);
                if(!data.success) {
                    this.errors.clear();
                    const errors = data.message;
                    const errorKeys = Object.keys(errors);
                    for (const key of errorKeys)
                        this.errors.add({ field: key, msg: errors[key][0] });
                    this.showLoading(false);

                    if(!this.currentUser.id) 
                        this.successNotif("Usuario creado", "Un correo ha sido enviado al usuario");
                    return;
                }

                this.$emit('on-save');
                this.showLoading(false);
            }
            catch (error) {
                console.log(error);
                this.errorMessage = 'Ha ocurrido un error al guardar el usuario. Por favor inténtalo de nuevo.';
            }
            this.showLoading(false);
        },
        handleClose() {
            this.$emit('on-close');
        },
        hasError(control) {
            return this.errors.has(control);
        },
        getErrorText(control) {
            return this.errors.first(control);
        },
        isSuccessful(control) {
            const field = this.fields[control];
            return (field && field.required && field.valid && !this.hasError(control));
        },
        clear() {
            this.errors.clear();
            this.errorMessage = null;
        }
    },
    computed: {
        userTypesList() {
            let options = [];
            if(this.isProgramAdmin){
                options = [ 
                    { value: this.SUBREGION_LEADER, text: 'Líder de ventas' },
                    { value: this.EXECUTIVE_MANAGER, text: 'Ejecutivo de programa' },
                    { value: this.ZONE_MANAGER, text: 'Gerente de zona' },
                    { value: this.REGION_MANAGER, text: 'Gerente de región' }
                ];
            } else {
                options = [ 
                    { value: this.SUBREGION_LEADER, text: 'Líder de ventas' }
                ];
                if(this.isZoneManager || this.isProgramAdmin) {
                    options.push({ value: this.REGION_MANAGER, text: 'Gerente de región' });
                }
                if(this.isProgramAdmin){
                    options.push({ value: this.EXECUTIVE_MANAGER, text: 'Ejecutivo de programa' });
                    options.push({ value: this.ZONE_MANAGER, text: 'Gerente de zona' }); 
                }
            }
            return options;
        },
        isExecutiveManager() {
            return this.$acl.check('isExecutiveManager');
		},
        isZoneManager() {
            return this.AuxGuardName == "program_zone_manager";
        },
        isRegionManager() {
            return this.AuxGuardName == "program_region_manager";
        },
        isProgramAdmin() {
            return this.$acl.check("isProgramAdmin");
        },
        getRegionsListOptions() {
            if(this.isRegionManager){
                this.currentUser.region = this.user.regions.id;
                console.log(this.user);
                return [{
                    value: this.user.regions.id,
                    text: this.user.regions.name
                }]
            }
            const region = this.regionsList.filter((region) => region.zone_id == this.currentUser.zone);
            return region.map((region) => ({value: region.id, text: region.name}));
        },
        getZonesListOptions() {
            if(this.currentUser.userType == this.REGION_MANAGER){
                const zones = this.zonesList.filter((zone) => zone.zone_manager_id != null);
                return zones.map((zone) => ({ value: zone.id, text: zone.name}))
            }
            return this.zonesList.map((zone) => ({ value: zone.id, text: zone.name }));
        }
    }
}
</script>